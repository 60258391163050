<template>
    <div>
        <div class="card" v-if="!loading">
            <!-- {{CRMByTopicId}} -->

            <!-- <div v-if="CRM">
                {{ CRM }}

            </div> -->
            <DataTable 
                :value="CRMByTopicId" 
                :loading="loading" 
                responsiveLayout="scroll" 
                showGridlines
                :paginator="true" 
                :rows="rowsPagValue" 
                :rowsPerPageOptions="[25, 50]" 
                :rowHover="true"
                :first="firstPagValue"
            >
                <template #header>
                    <div>
                        <div style="display: flex; align-items: center;">
                            <!-- url helper -->
                            <h5 style="margin-bottom: 0;" v-if="selectedTopic">Critical Raw Materials for:
                                {{ selectedTopic.name }}</h5>
                            <Button class="p-button-raised p-button-rounded my-3 p-button-help mx-3"
                                icon="pi pi-question-circle" />
                            <!-- @click="goToHeaderPage('documentsOverview-header')" -->
                        </div>
                    </div>

                    <div class="table-header row gy-3 d-flex flex-row justify-content-between">
                        <div class="col-sm-12 col-md-4 d-flex flex-row justify-content-md-start">
                            <Button @click="openAddCRMTopicDialog" type="button" icon="pi pi-plus" label="Add CRM" class="p-button-outlined" />
                            
                        </div>
                    </div>
                </template>

                <!-- RawMaterial -->
                <Column header="Raw Material" style="min-width: 5rem;">
                    <!-- sortable -->
                    <!-- field="RawMaterial"
                    header="Raw Material" -->
                    <template #body="slotProps">
                        <div>
                            <!-- {{ slotProps }} -->
                            {{ slotProps.data.crm.rawMaterial }}
                        </div>
                    </template>
                </Column>
                <!-- Abb -->
                <Column header="Abb." style="min-width: 5rem;">
                    <!-- sortable -->
                    <!-- field="Abbreviation" -->
                    <template #body="slotProps">
                        <div>
                            {{ slotProps.data.crm.rawMaterialSymbol }}
                        </div>
                    </template>
                </Column>

                <Column header="Indicative usage" style="min-width: 5rem;">
                    <!-- field="indicativeUsage" -->
                    <template #body="slotProps">
                        <div v-if="slotProps.data.id === changeToEdit"
                            style="display: flex; justify-content: space-between;">
                            <InputText v-model="slotProps.data.minMaterialUsage" style="max-width: 3rem;"  @keypress="onlyNumbers" /> -
                            <InputText v-model="slotProps.data.maxMaterialUsage" style="max-width: 3rem;"  @keypress="onlyNumbers"/>
                        </div>
                        <div v-else>
                            {{ slotProps.data.minMaterialUsage }}-{{ slotProps.data.maxMaterialUsage }}
                        </div>
                    </template>
                </Column>

                <Column field="primaryUses" header="Primary Uses" style="min-width: 30rem;">
                    <template #body="slotProps">
                        <div v-if="slotProps.data.id === changeToEdit">
                            <InputText v-model="slotProps.data.primaryUsage" style="width: 100%;"></InputText>
                        </div>
                        <div v-else>
                            {{ slotProps.data.primaryUsage }}
                        </div>
                    </template>
                </Column>

                <Column style="width: 10rem;">
                    <template #body="slotProps">
                        <div style="display: flex; justify-content: space-around;"
                            v-if="slotProps.data.id !== changeToEdit">
                            <!-- #body="slotProps" -->
                            <div>
                                <Button class="p-button-raised p-button-rounded p-button-success" icon="pi pi-pencil"
                                    @click="editField(slotProps.data)" />
                                <!-- @click="edit(slotProps.data)" -->
                            </div>

                            <div>
                                <Button 
                                    icon="pi pi-trash" 
                                    class="p-button-rounded p-button-danger" 
                                    @click="openRemoveDialog(slotProps.data)"/>
                                <!-- @click="openDeleteMultipleDocumentsDialog()" -->
                            </div>

                        </div>

                        <div style="display: flex; justify-content: space-around;" v-else>
                            <!-- #body="slotProps" -->
                            <div>
                                <Button class="p-button-raised p-button-rounded p-button-success" icon="pi pi-save"
                                    @click="editData(slotProps.data)">
                                </Button>
                                <!-- @click="edit(slotProps.data)" -->
                            </div>

                            <div>
                                <Button class="p-button-rounded p-button-danger" icon="pi pi-times"
                                    @click="closeEditField" />
                                <!--  -->
                            </div>
                        </div>
                    </template>
                </Column>
            </DataTable>
        </div>
        <div v-else>
            <div class="row">
                <div class="col-12 py-4 d-flex justify-content-center">
                    <ProgressSpinner />
                </div>
            </div>
        </div>
    </div>

    <Dialog
        v-model:visible="showAddCRMTopic"
        :style="{width: '80rem'}"
        :header="(selectedTopic)?'Add CRM to '+ selectedTopic.name :'Add CRM Topic'"
        :modal="true"
        @hide="closeDialog"
    >
        <div class="field"  style="padding: 1rem;">
            <div style="display: flex; align-items: center; gap:1rem; margin-bottom: 1rem;">
                <label for="crm" style="width: 20%;">Critical Raw Material: </label>
                <Dropdown 
                    v-model="addCRMTopicForm.rawMaterialId"
                    :options="CRM"
                    optionLabel="rawMaterial"
                    optionValue="rawMaterialId"
                    class="p-button-outlined me-4"
                    style="width: 70%"
                    :filter="true"
                />
            </div>
            <div style="display: flex;  gap:1rem; margin-bottom: 1rem;">
                <label for="primaryUsage" style="width: 20%;">Primary Usage</label>
                <Textarea
                  id="inventory"
                  rows="5"
                  cols="30"
                  v-model="addCRMTopicForm.primaryUsage"
                  style="width: 70%;"
                />
            </div>

            <div style="display: flex;  gap:1rem; margin-bottom: 1rem;">
                <label for="minMaterialUsage" style="width: 20%;">Min material usage</label>
                <InputText v-model="addCRMTopicForm.minMaterialUsage" style="width: 30%;"   @input="validateAddNumberInput($event, false)"  />
            </div>

            <div style="display: flex;  gap:1rem; margin-bottom: 1rem;">
                <label for="maxMaterialUsage" style="width: 20%;">Max material usage</label>
                <InputText v-model="addCRMTopicForm.maxMaterialUsage" style="width: 30%;" @input="validateAddNumberInput($event, true)" />
            </div>

            
        </div>
        <template #footer>
            <Button
                label="Cancel"
                icon="pi pi-times"
                class="p-button-text"
                @click="closeDialog"
            />
            <Button 
                label="Save"
                icon="pi pi-check"
                class="p-button-text"
                :loading="addCRMTopicLoading"
                @click="addCRMTopic"
            />
        </template>
    </Dialog>


    <Dialog
        v-model:visible="showDeleteCRMTopic"
        :style="{width: '450px'}"
        :header="(selectedTopic)?'Remove CRM from '+ selectedTopic.name :'Remove CRM'"
        :modal="true"
        @hide="closeDialog"
    >
        <div class="confirmation-content d-flex flex-row">
            <i class="pi pi-exclamation-triangle me-3" style="font-size: 2rem" />
                <span>
                    Are you sure you want to remove 
                    <strong>{{removeCRMTopic.crm.rawMaterial}}</strong>
                    ?
            </span>
        </div>
        
        <template #footer>
            <Button
                label="No"
                icon="pi pi-times"
                class="p-button-text"
                @click="closeDialog"
            />
            <Button 
                label="Yes"
                icon="pi pi-check"
                class="p-button-text"
                @click="removeCRMFromTopic"
                :loading="removeLoading"
            />
        </template>

    </Dialog>
</template>

<script>
import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';
import ProgressSpinner from 'primevue/progressspinner';
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import Textarea from 'primevue/textarea';
import { useToast } from 'vue-toastification';

const toast = useToast();

export default {
    name: 'CRMList',
    components: {
        DataTable,
        Column,
        Button,
        InputText,
        ProgressSpinner,
        Dialog,
        Dropdown,
        Textarea,
    },
    data() {
        return {
            loading: true,
            rowsPagValue: 25,
            firstPagValue: 0,

            crmList: [],
            crmEdit: null,
            changeToEdit: null,

            showAddCRMTopic: false, 
            addCRMTopicForm: {
                rawMaterialId:null,
                topicId: null,
                primaryUsage: null,
                minMaterialUsage: null,
                maxMaterialUsage: null,
            },
            addCRMTopicLoading:false,

            showDeleteCRMTopic:false,
            removeCRMTopic:null,
            removeLoading:false,
        }
    },
    async mounted() {
    },
    computed: {
        selectedTopic() {
            return this.$store.getters.obtainSelectedTopic;
        },
        CRM() {
            let crmList = [...this.$store.getters.obtainCrm]; //Clone CRM 

            // Remove CRM that are in CRMByTopicId
            if (this.CRMByTopicId && this.CRMByTopicId.length) {
                const crmTopicArray = new Set(this.CRMByTopicId.map(item => item.crm.rawMaterialId));
                crmList = crmList.filter(crm => !crmTopicArray.has(crm.rawMaterialId));
            }

            // Sort Alphabetically
            crmList.sort((x, y) => (x.rawMaterial > y.rawMaterial ? 1 : x.rawMaterial < y.rawMaterial ? -1 : 0));

            return crmList;
        },

        CRMByTopicId() {
            let crmByTopicIdList = [...this.$store.getters.obtainCrmByTopicId];
            crmByTopicIdList.sort((x, y) => (x.crm.rawMaterial > y.crm.rawMaterial ? 1 : x.crm.rawMaterial < y.crm.rawMaterial ? -1 : 0));

            return crmByTopicIdList;
        }
    },
    methods: {
        async fetchCRMData(){
           this.loading = true; // Opcional, si manejas estados de carga
            try {
                await this.getAllCRMByTopicId();
                await this.getAllCRM();
                this.setCRMList();
            } catch (error) {
                console.error("Error fetching CRM data:", error);
            } finally {
                this.loading = false;
            }
        },

        async getAllCRM() {
            try {
                await this.$store.dispatch('obtainCrm');
            } catch (error) {
                console.error("Error getting AllCrm : ", error);
            }
        },

        async getAllCRMByTopicId() {
            try {
                await this.$store.dispatch('obtainCRMByTopicId', this.selectedTopic.topicId);
            } catch (error) {
                console.error('Error Getting AllCRMByTopicId', error);
            }
        },

        setCRMList() {
            this.crmList = this.CRMByTopicId;
        },

        openAddCRMTopicDialog(){
            this.showAddCRMTopic = true;
        },
        async addCRMTopic(){
            try {
                this.addCRMTopicLoading = true;
                const addCRMTopicForm = {
                    rawMaterialId : this.addCRMTopicForm.rawMaterialId,
                    topicId: this.selectedTopic.topicId,
                    primaryUsage: this.addCRMTopicForm.primaryUsage,
                    minMaterialUsage: this.addCRMTopicForm.minMaterialUsage,
                    maxMaterialUsage: this.addCRMTopicForm.maxMaterialUsage,
                } 
                await this.$store.dispatch('addCRMByTopicId', addCRMTopicForm )
                this.addCRMTopicLoading = false; 
                this.cleanAddCRMTopic();
                this.closeDialog();
            } catch (error) {
                console.error('Error on Add: ', error);
                toast.error(error.message);
            }
        },

        cleanAddCRMTopic(){
            this.addCRMTopicForm.rawMaterialId = null
            this.addCRMTopicForm.topicId = null
            this.addCRMTopicForm.primaryUsage = null
            this.addCRMTopicForm.minMaterialUsage = null
            this.addCRMTopicForm.maxMaterialUsage = null
        },

        editField(data) {
            this.changeToEdit = data.id;
        },

        closeEditField() {
            this.changeToEdit = null;
        },

        async editData(data) {
            let dataJSONStringify = JSON.parse(JSON.stringify(data))

            let crmId = dataJSONStringify.id
            let crmTopicData = dataJSONStringify
            delete crmTopicData.crm
            // delete crmTopicData.id;
            try {
                await this.$store.dispatch('updateCRMByTopicId', { crmTopicData, crmId })
                this.closeEditField()
            } catch (error) {
                console.error('Error on Edit: ', error);
                toast.error(error.message);
            }

        },

        openRemoveDialog(data){
            this.showDeleteCRMTopic= true;
            this.removeCRMTopic = data;
        },

        closeDialog(){
            this.showDeleteCRMTopic = false;
            this.showAddCRMTopic = false;
        },

        async removeCRMFromTopic(){
            try {
                this.removeLoading = true
                let crmTopicId = this.removeCRMTopic.id
                await this.$store.dispatch('deleteCRMByTopicId',crmTopicId)
                this.removeLoading = false
                this.closeDialog();
            } catch (error) {
                console.error('Error removing CRMTOpic :',error);
                toast.error(error.message);
            }

        },

        onlyNumbers(event) {
            const char = String.fromCharCode(event.keyCode);
            
            // Permitir solo números y un único punto decimal
            if (!/[\d.]/.test(char)) {
                event.preventDefault();
                return;
            }

            // Evitar más de un punto decimal
            if (char === "." && event.target.value.includes(".")) {
                event.preventDefault();
            }
        },
        validateAddNumberInput(event, minMax){
            let value = event.target.value;
            if (!/^\d*\.?\d*$/.test(value)) {
                event.target.value = value.slice(0, -1);
            }
            if(!minMax){
                this.addCRMTopicForm.minMaterialUsage = event.target.value
            }
            else{
                this.addCRMTopicForm.maxMaterialUsage = event.target.value
            }

        }
    },

    watch: {
        selectedTopic: {
            immediate: true, // Ejecuta al inicio si selectedTopic ya tiene valor
            handler(newTopic) {
                if (newTopic) {
                    this.fetchCRMData();
                }
            }
        }
    }

}
</script>
<style></style>